.menu {
  position: fixed;
  top: 50px;
  left: -300px;
  height: calc(100vh - 50px);
  width: 298px;
  color: var(--text-color);
  background-color: var(--background-color);
  border-right: 2px solid var(--primary-color);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &-header {
    width: calc(100% - 40px);
    padding: 10px 20px;
    font-size: xx-large;
    font-weight: 900;
    text-align: left;
    margin-top: 8px;
  }

  &-body {
    width: 100%;
    padding-top: 10px;
    text-align: left;
    flex: 1;
  }

  &-footer {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
  }

  .user-pages {
    margin-bottom: 20px;
  }

  .admin-pages {
    &__title {
      font-weight: 900;
      margin: 8px 20px;
    }
  }

  .theme-toggle-button {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 400px) {
  .menu {
    left: calc((100% * -1) - 2px);
    width: 100%;
  }
}
