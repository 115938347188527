@import '../../../styles/mixins';

@mixin before ($primary-color) {
  animation: fanfare-before 1s linear;
  background-color: $primary-color;
  border-radius: 16px;
  clip-path: polygon(233.3% 0%, 333.3% 0%, 300% 100%, 200% 100%);
  content: ' ';
  height: 100%;
  left: 0;
  opacity: .5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.end-modal-parent {
  &.hard-mode {
    &::before {
      @include before(red);
    }
  }

  &::before {
    @include before(yellowgreen);
  }

  &::after {
    animation: fanfare-after 1s linear;
    background-color: gold;
    border-radius: 16px;
    clip-path: polygon(133.3% 0%, 233.3% 0%, 200% 100%, 100% 100%);
    content: ' ';
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

.end-modal-body {
  visibility: visible;
}

.end-modal-footer {
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__link {
    border: none;
    margin-top: 16px;
    padding: 0px;
    background-color: inherit;
    font-size: 16px;
    text-decoration: underline;
    color: var(--disabled-color);
    user-select: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: color .2s linear;

    &:focus,
    &:hover {
      color: var(--primary-color);
    }
  }
}

.end-modal-button {
  margin-left: 8px;
  margin-right: 8px;
}

.end-modal-text {
  margin: 0px 15px 0px;
  text-align: center;
}

.header-message {
  @include fanfare("easy", yellowgreen);

  &.hard-mode {
    @include fanfare("hard", red);
  }
}

@keyframes fanfare-before {
  0% {
    clip-path: polygon(-100% 0%, -50% 0%, -75% 100%, -125% 100%);
  }

  100% {
    clip-path: polygon(225% 0%, 275% 0%, 250% 100%, 200% 100%);
  }
}

@keyframes fanfare-after {
  0% {
    clip-path: polygon(-150% 0%, -100% 0%, -125% 100%, -175% 100%);
  }

  100% {
    clip-path: polygon(175% 0%, 225% 0%, 200% 100%, 150% 100%);
  }
}
