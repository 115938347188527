.keyboard-key-parent {
  position: relative;
  line-height: 52px;
  height: 52px;
  width: 52px;
  margin: 4px;
  box-sizing: content-box;

  &.long {
    width: 77px;
  }

  .keyboard-key {
    font-size: 16px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
    cursor: default;
    user-select: none;
  }
}

.front {
  height: 100%;
  width: 100%;
  backface-visibility: hidden;

  &.flipped {
    transform: rotateY(-180deg);
    transition: transform 1s linear;
  }

  .keyboard-key {
    background-color: var(--background-color);
    border: 1px solid var(--disabled-color);
    color: var(--disabled-color);
  
    &:hover {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      transform: scale(1.1);
    }

    &:active {
      background-color: var(--disabled-color);
      transform: scale(1);
      transition: background-color 0s;
    }
  }
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;

  &.flipped {
    transform: rotateY(0deg);
    transition: transform 1s linear;
  }

  .incorrect {
    background-color: var(--disabled-color);
    border: 1px solid var(--disabled-color);
    color: var(--secondary-color);
    transition:
      background-color 1s linear,
      border 1s linear,
      color 1s linear;
  
    &:hover {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      transform: scale(1.1);
    }

    &:active {
      background-color: var(--disabled-color);
      transform: scale(1);
      transition: background-color 0s;
    }
  }
  
  .correct-letter {
    background-color: gold;
    border: 1px solid gold;
    color: var(--secondary-color);
    transition:
      background-color 1s linear,
      border 1s linear,
      color 1s linear;
  
    &:hover {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      transform: scale(1.1);
    }

    &:active {
      background-color: var(--disabled-color);
      transform: scale(1);
      transition: background-color 0s;
    }
  }
  
  .correct {
    background-color: yellowgreen;
    border: 1px solid yellowgreen;
    color: var(--secondary-color);
    transition:
      background-color 1s linear,
      border 1s linear,
      color 1s linear;
  
    &:hover {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      transform: scale(1.1);
    }

    &:active {
      background-color: var(--disabled-color);
      transform: scale(1);
      transition: background-color 0s;
    }
    
    &.hard-mode {
      background-color: red;
      border: 1px solid red;

      &:hover {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }

      &:active {
        background-color: var(--disabled-color);
        transform: scale(1);
        transition: background-color 0s;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .keyboard-key-parent {
    width: 30px;
    margin: 1px;

    &.long {
      width: 45px;
      font-size: 12px;
    }
  }
}
