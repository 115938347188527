.board-cell {
  line-height: 52px;
  height: 52px;
  width: 52px;
  margin: 4px;
  position: relative;

  .front {
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    text-align: center;
    margin: auto;
    cursor: default;
    user-select: none;
    font-weight: 600;
    font-size: x-large;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;

    &.flipped {
      transform: rotateY(-180deg);
      transition: transform 1s linear;
    }

    &.empty {
      background-color: var(--background-color);
      border: 1px solid var(--disabled-color);
    }

    &.non-empty {
      background-color: var(--background-color);
      border: 1px solid var(--primary-color);
      color: var(--text-color);

      &:not(.flipped) {
        animation: expand-contract 0.2s;
      }

      &.invalid {
        animation: flash 0.2s linear;
      }
    }
  }

  .back {
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    text-align: center;
    cursor: default;
    user-select: none;
    font-weight: 600;
    font-size: x-large;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
    backface-visibility: hidden;

    &.flipped {
      transform: rotateY(0);
      transition: transform 1s linear;
    }

    &.incorrect {
      background-color: var(--disabled-color);
      border: 1px solid var(--disabled-color);
      color: var(--secondary-color);
    }

    &.incorrect-final {
      background-color: var(--error-color);
      border: 1px solid var(--error-color);
      color: var(--secondary-color);
    }

    &.correct-letter {
      background-color: gold;
      border: 1px solid gold;
      color: var(--secondary-color);
    }

    &.correct {
      background-color: yellowgreen;
      border: 1px solid yellowgreen;
      color: var(--secondary-color);

      &.hard-mode {
        background-color: red;
        border: 1px solid red;
      }
    }
  }
}

@keyframes expand-contract {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes flash {
  0% {
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
    color: var(--text-color);
  }

  50% {
    background-color: var(--error-color);
    border: 1px solid var(--error-color);
    color: var(--secondary-color);
  }

  100% {
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
    color: var(--text-color);
  }
}
