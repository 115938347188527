.main-page {
  position: relative;
}

.main-page__header {
  position: absolute;
  top: 0px;
  height: 50px;
  width: 100%;
  z-index: 20;
}

.main-page__body {
  position: absolute;
  top: 50px;
  width: 100%;
  height: calc(100vh - 50px);
}

.main-page__backdrop {
  position: absolute;
  height: calc(100vh - 50px);
  width: 100%;
  opacity: 0.4;
  background-color: var(--primary-color);
  z-index: 19;
}

.menu-enter-active {
  left: 0px;
  transition: left 250ms ease-in;
  z-index: 20;
}

.menu-enter-done {
  left: 0px;
  z-index: 20;
}

.menu-exit {
  left: 0px;
  z-index: 20;
}

.menu-exit-active {
  left: -300px;
  transition: left 150ms ease-out;
  z-index: 20;
}

.login-enter-active {
  right: 0px;
  transition: right 250ms ease-in;
  z-index: 20;
}

.login-enter-done {
  right: 0px;
  z-index: 20;
}

.login-exit {
  right: 0px;
  z-index: 20;
}

.login-exit-active {
  right: -400px;
  transition: right 150ms ease-out;
  z-index: 20;
}

.toast-column {
  position: absolute;
  bottom: 50px;
  left: 50px;
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
}

@media only screen and (max-width: 400px) {
  .menu-exit-done {
    left: calc((100% * -1) - 2px);
    transition: left 250ms ease-out;
    z-index: 20;
  }
}
