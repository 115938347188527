.feature-request {
  padding: 15px;
  border-top: 1px var(--text-color) solid;

  &.first-entry {
    padding-top: 0px;
    border-top: none;
  }

  .info.completed {
    color: var(--disabled-color);
  }

  .field {
    margin-bottom: 8px;
  }

  .title {
    font-weight: 900;
  }

  .action-buttons {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
  
    .complete-button {
      text-align: center;
      width: auto;
      margin-right: 4px;
    }

    .delete-button {
      text-align: center;
      width: auto;
      margin-left: 4px;
    }
  }
}
