.disclaimer-modal__body {
  text-align: center;
}

.disclaimer-modal__button {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  cursor: default;
  user-select: none;
  padding: 8px;
  font-weight: 900;
  margin-left: 8px;
  margin-right: 8px;
}

.disclaimer-modal__button:active {
  background-color: var(--disabled-color);
  color: var(--secondary-color);
}
