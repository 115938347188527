.user-options {
    position: fixed;
    top: 50px;
    right: -400px;
    height: 100%;
    width: 398px;
    color: var(--text-color);
    background-color: var(--background-color);
    border-left: 2px solid var(--primary-color);
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-options__header {
    font-size: xx-large;
    font-weight: 900;
    text-align: center;
    margin-top: 8px;
  }

.user-options__body {
    padding: 20px;
    text-align: center;
}

.user-options__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-options__button {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 20px;
}

.user-options__label {
    margin-top: 0px;
    margin-bottom: 4px;
}

.user-options__input {
    font-size: medium;
    height: 24px;
    width: 200px;
    margin-top: 0px;
    margin-bottom: 16px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    color: var(--text-color);
    background-color: var(--background-color);
}  

@media only screen and (max-width: 400px) {
    .user-options {
        right: calc((100% * -1) - 2px);
        width: 100%;
    }
}

