.page-link {
  cursor: pointer;
  padding: 8px 20px;
  color: var(--text-color);
  background-color: inherit;
  user-select: none;
  border: none;
  width: 100%;
  font-size: 16px;
  text-align: left;

  &.selected {
    font-weight: 900;
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
}