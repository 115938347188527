.button {
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  cursor: default;
  user-select: none;
  padding: 8px;
  font-size: 16px;
  font-weight: 900;
  position: relative;
  top: 0;
  box-shadow: none;
  transition: box-shadow 0.3s, top 0.3s;

  &:active {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    box-shadow: none;
    transition: box-shadow 0.1s, top 0.1s;
  }

  &:hover:not(.disabled, :active) {
    box-shadow: 0 2px 2px 1px var(--button-shadow-color);
    top: -2px;
    animation: wiggle 0.5s;
  }

  &.success {
    border: 1px solid yellowgreen;
    color: yellowgreen;
    transition: all 0.5s linear;

    &:active {
      background-color: yellowgreen;
      color: var(--secondary-color);
      transition: color 0s linear, background-color 0s linear, box-shadow 0.1s, top 0.1s;

      &.disabled {
        background-color: var(--background-color);
        border: 1px solid yellowgreen;
        color: yellowgreen;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:not(.success) {
      border: 1px solid var(--disabled-color);
      color: var(--disabled-color);

      &:active {
        background-color: var(--background-color);
        border: 1px solid var(--disabled-color);
        color: var(--disabled-color);
      }
    }
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(1deg);
  }

  75% {
    transform: rotate(-1deg);
  }
  
  100% {
    transform: rotate(0deg);
  }
}