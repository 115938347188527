.login {
  position: fixed;
  top: 50px;
  right: -400px;
  height: 100%;
  width: 398px;
  color: var(--text-color);
  background-color: var(--background-color);
  border-left: 2px solid var(--primary-color);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-header {
  font-size: xx-large;
  font-weight: 900;
  text-align: center;
  margin-top: 8px;
}

.login-header__error {
  font-size: medium;
  font-weight: 400;
  color: var(--error-color);
  border: 1px solid var(--error-color);
  padding: 0px 8px;
  margin: 4px 16px;
}

.login-header__error-message {
  margin: 8px 0px;
}

.login-body {
  padding: 20px;
  text-align: center;
}

.login-footer {
  padding: 20px;
  text-align: center;

  &__link {
    border: none;
    padding: none;
    background-color: inherit;
    font-size: 16px;
    text-decoration: underline;
    color: var(--disabled-color);
    user-select: none;
    cursor: pointer;
    transition: color 0.2s linear;

    &:focus,
    &:hover {
      color: var(--primary-color);
    }
  }
}

.login-button__primary {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 20px;
}

.login-button__secondary {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.login-label {
  margin-top: 0px;
  margin-bottom: 4px;
}

.login-label__invalid {
  margin-top: 0px;
  margin-bottom: 4px;
  color: var(--error-color);
}

.login-input {
  font-size: medium;
  height: 24px;
  width: 200px;
  margin-top: 0px;
  margin-bottom: 16px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--text-color);
  background-color: var(--background-color);
}

.login-input__invalid {
  font-size: medium;
  height: 24px;
  width: 200px;
  margin-top: 0px;
  margin-bottom: 16px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--error-color);
  background-color: var(--background-color);
}

@media only screen and (max-width: 400px) {
  .login {
    right: calc((100% * -1) - 2px);
    width: 100%;
  }
}
