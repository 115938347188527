.feature-requests {
  color: var(--text-color);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.feature-requests__title {
  padding-top: 20px;
  text-align: center;
  font-size: xx-large;
  font-weight: 900;
}

.feature-requests__body {
  width: 500px;
  margin-top: 15px;
  text-align: left;
}

@media only screen and (max-width: 550px) {
  .feature-requests__body {
    width: 300px;
  }
}

