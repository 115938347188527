@mixin scroll-animation ($start, $distance) {
  @keyframes scroll-#{$start} {
    0% {
      top: $start;
    }

    100% {
      top: $start + $distance
    }
  }
}

@mixin fanfare-animation ($mode, $modeColor) {
  @keyframes fanfare-#{$mode} {
    0% {
      color: var(--primary-color);
      transform: scale(1);
    }

    33% {
      color: $modeColor;
    }

    50% {
      transform: scale(1.1);
    }

    66% {
      color: gold;
    }

    100% {
      color: var(--primary-color);
      transform: scale(1);
    }
  }
}

@mixin scroll ($start, $distance) {
  animation: scroll-#{$start} 1s ease-out forwards;

  @include scroll-animation($start, $distance);
}

@mixin fanfare ($mode, $modeColor) {
  animation: fanfare-#{$mode} 2s linear infinite;

  @include fanfare-animation($mode, $modeColor);
}

@mixin scroll-with-fanfare ($start, $distance, $mode, $modeColor) {
  animation: scroll-#{$start} 1s ease-out forwards, fanfare-#{$mode} 2s linear infinite;

  @include scroll-animation($start, $distance);
  @include fanfare-animation($mode, $modeColor);
}
