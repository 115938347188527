.toast-body {
  color: var(--text-color);
  background-color: var(--background-color);
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  height: auto;
  width: 200px;
  box-shadow: 0 10px 15px 5px var(--modal-shadow-color);
  animation: appear 0.25s;
  padding: 32px;
}
