.header {
  height: 100%;
  width: 100%;
  border-bottom: 2px solid var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color);
  background-color: var(--background-color);
}

.title {
  flex: 1;
  text-align: center;
  font-size: x-large;
  font-weight: 700;
}

.icon1 {
  left: 0;
  cursor: pointer;
  padding: 0;
  border: none;
  height: 50px;
  background-color: inherit;
}

.icon2 {
  right: 0;
  cursor: pointer;
  padding: 0;
  border: none;
  height: 50px;
  background-color: inherit;
}
