.backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 300;
  left: 0;
  top: 0;
  background-color: var(--primary-color);
  opacity: 0.4;
}

.modal {
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 500px;
  color: var(--text-color);
  background-color: var(--background-color);
  border: 2px solid var(--primary-color);
  border-radius: 16px;
  box-shadow: 0 10px 15px 5px var(--modal-shadow-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 400;
  animation: appear 0.25s;

  .modal-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    padding: 0;
    border: none;
    height: 30px;
    width: 30px;
    background: transparent;
    transition: right 0.25s, top 0.25s, height 0.25s, width 0.25s;

    .icon {
      width: 100%;
      height: 100%;
    }

    &:focus:not(:active),
    &:hover:not(:active) {
      height: 40px;
      width: 40px;
      right: 5px;
      top: 5px;
    }

    &:active {
      height: 30px;
      width: 30px;
      right: 10px;
      top: 10px;
    }
  }

  .modal-header {
    font-size: xx-large;
    font-weight: 900;
    text-align: center;
    margin-top: 16px;
  }
  
  .modal-body {
    width: calc(100% - 30px);
    margin: 15px;
  }
  
  .modal-footer {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 500px) {
  .modal {
    height: auto;
    width: 340px;

    .modal-header {
      margin-top: 8px;
    }
  
    .modal-body {
      width: calc(100% - 16px);
      margin: 8px;
      max-height: 350px;
      overflow: scroll;
    }
  
    .modal-footer {
      margin-bottom: 16px;
    }
  }
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(.5);
    opacity: 0;
  }

  80% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
