.steword {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: var(--text-color);
  background-color: var(--background-color);
}

.title {
  padding-top: 20px;
  text-align: center;
  font-size: xx-large;
  font-weight: 900;

  &.animate {
    .S {
      position: relative;
      animation: bob 0.5s 1s;
    }
  
    .T {
      position: relative;
      animation: bob 0.5s 1.1s;
    }
  
    .E {
      position: relative;
      animation: bob 0.5s 1.2s;
    }
  
    .W {
      position: relative;
      animation: bob 0.5s 1.3s;
    }
  
    .O {
      position: relative;
      animation: bob 0.5s 1.4s;
    }
  
    .R {
      position: relative;
      animation: bob 0.5s 1.5s;
    }
  
    .D {
      position: relative;
      animation: bob 0.5s 1.6s;
    }
  }
}

.results {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .results-button {
    text-align: center;
    vertical-align: middle;
    margin: 0px 8px 8px 8px;
    width: 150px;
  }

  .change-mode-link {
    border: none;
    padding: none;
    background-color: inherit;
    font-size: 16px;
    text-decoration: underline;
    color: var(--disabled-color);
    user-select: none;
    cursor: pointer;
    transition: color 0.2s linear;

    &:focus,
    &:hover {
      color: var(--primary-color);
    }
  }
}

.toast-player-name {
  font-weight: 900;
}

@media only screen and (max-width: 500px) {
  .title {
    padding-top: 8px;
  }
}

@keyframes bob {
  0% {
    top: 0px;
  }

  50% {
    top: -5px;
  }

  100% {
    top: 0px;
  }
}