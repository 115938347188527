.leaderboard {
  margin: 8px 0px 15px;
}

.leaderboard-header {
  display: flex;
  flex-direction: row;
}

.leaderboard-title {
  font-weight: 900;
  text-align: center;
  flex: 1;
}

.leaderboard-answer {
  margin-top: 8px;
  text-align: center;

  .answer-word {
    font-weight: 900;
  }
}

.leaderboard-scores-list {
  margin: 8px 0px 16px 0px;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.leaderboard-link {
  border: none;
  margin-bottom: 8px;
  padding: 0px;
  background-color: inherit;
  font-size: 16px;
  text-decoration: underline;
  user-select: none;
  width: 100%;
  text-align: center;
  cursor: pointer;

  a {
    color: var(--disabled-color);
    transition: color .2s linear;

    &:focus,
    &:hover {
      color: var(--primary-color);
    }
  }
}

.leaderboard-score-submit__button {
  margin-right: 15px;
}

.leaderboard-score-submit__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.leaderboard-score-submit__text {
  margin: 0px 15px 15px;
  text-align: center;
}

.leaderboard-score-submit__label {
  margin: 0px 16px 0px 16px;
  font-weight: 600;
}

.leaderboard-score-submit__input {
  font-size: medium;
  height: 24px;
  width: 150px;
  margin-top: 0px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--text-color);
  background-color: var(--background-color);
}

.leaderboard-button__back {
  display: flex;
  justify-content: flex-end;
  width: 80px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
    opacity: .3;
  }
}

.leaderboard-button__forward{
  display: flex;
  justify-content: flex-start;
  width: 80px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
    opacity: .3;
  }
}

.back-icon {
  transform: rotate(270deg);
}

.forward-icon {
  transform: rotate(90deg);
}

@media only screen and (max-width: 500px) {
  .leaderboard {
    margin: 8px 0px 8px;
  }

  .leaderboard-score-submit__button {
    margin: 15px 0px 0px;
  }

  .leaderboard-scores-list {
    max-height: 90px;
  }

  .leaderboard-button__back {
    width: 50px;
  }
  
  .leaderboard-button__forward{
    width: 50px;
  }
}
