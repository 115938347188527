.feature-request-modal {
  &-footer {
    display: flex;
    flex-direction: column;

    &__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }
  
    &__link {
      cursor: pointer;
      text-decoration: underline;
      user-select: none;
      text-align: center;
    }

    .feature-request-modal__text {
      font-weight: 900;
      border: 1px solid yellowgreen;
      color: yellowgreen;
      padding: 8px;
    }
  }
  
  &__button {
    margin-left: 8px;
    margin-right: 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  &__text {
    margin-bottom: 15px;
    text-align: center;
  }

  &__textarea {
    resize: none;
    height: 200px;
    width: calc(100% - 15px);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    color: var(--text-color);
    background-color: var(--background-color);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    &:disabled {
      background-color: var(--disabled-color);
    }
  }

  &__label {
    margin: 0px 16px 0px 16px;
    font-weight: 600;
  }

  &__input {
    font-size: medium;
    height: 24px;
    width: 150px;
    margin-top: 0px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    color: var(--text-color);
    background-color: var(--background-color);

    &:disabled {
      background-color: var(--disabled-color);
    }
  }
}
