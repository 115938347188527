.guest-book {
    color: var(--text-color);
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.guest-book__title {
    padding-top: 20px;
    text-align: center;
    font-size: xx-large;
    font-weight: 900;
}

.guest-book__body {
    padding-top: 20px;
    text-align: center;
}