.announcement-modal__body {
  .text {
    &-strong {
      font-weight: 900;
    }

    &-red {
      color: red;

      &-highlight {
        color: white;
        background-color: red;
      }
    }

    &-green-highlight {
      color: black;
      background-color: yellowgreen;
    }
  }
}
