// Default theme
.default-theme {
  --text-color: black;
  --background-color: white;
  --primary-color: black;
  --secondary-color: white;
  --disabled-color: gray;
  --error-color: pink;
  --modal-shadow-color: rgba(0, 0, 0, .3);
  --button-shadow-color: rgba(0, 0, 0, .4);
}

// Dark theme
.dark-theme {
  --text-color: white;
  --background-color: #111111;
  --primary-color: white;
  --secondary-color: #111111;
  --disabled-color: gray;
  --error-color: pink;
  --modal-shadow-color: rgba(0, 0, 0, .3);
  --button-shadow-color: rgba(255, 255, 255, .6);
}
