@import '../../styles/mixins';

.title {
  width: 100%;
  text-align: center;
  font-weight: 900;
}

.stats {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 15px 0px 15px;

  .streak-stats {
    flex: 3;
    display: flex;
    flex-direction: row;
    text-align: center;
    font-weight: 600;
    font-size: 12px;

    .current-streak {
      flex: 1;
    }

    .highest-streak {
      flex: 1;
    }

    .value {
      font-size: 48px;
      font-weight: 900;
      position: relative;
      overflow: hidden;
      margin: 8px 0px;

      .new-streak {
        height: 100%;
        width: 100%;
        position: absolute;
        top: -57px;

        &.scroll {
          @include scroll-with-fanfare(-57px, 57px, "easy", yellowgreen);

          &.hard-mode {
            @include scroll-with-fanfare(-57px, 57px, "hard", red);
          }
        }
      }

      .old-streak {
        height: 100%;
        width: 100%;
        position: relative;
        
        &.scroll {
          @include scroll(0px, 57px);
        }
      }
    }
  }

  .guess-stats {
    flex: 2;

    .label {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
    }

    .stats-grid {

      .guess-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .guess-label {
          flex: 1;
          font-weight: 600;
          font-size: 12px;
        }
  
        .guess-graph {
          flex: 9;
          margin: 4px;

          .guess-bar {
            background-color: var(--primary-color);
            height: 100%;
          }
        }
      }
    }
  }
}
