.leaderboard-row {
  display: grid;
  width: auto;

  .place-cell {
    grid-row: 1;
    grid-column: 1;
    text-align: end;
    width: 80px;
  }

  .name-cell {
    grid-row: 1;
    grid-column: 2;
    text-align: center;
    width: 175px;
  }

  .score-cell {
    grid-row: 1;
    grid-column: 3;
    width: 40px;
  }

  .expand-cell {
    grid-row: 1;
    grid-column: 4;
    width: 40px;
    opacity: .3;

    .collapsed {
      transform: rotate(180deg);
      transition: transform .25s;
    }

    .expanded {
      transition: transform .25s;
    }
  }

  .results-display {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: span 4;
    text-align: center;
    white-space: pre-wrap;
    font-size: 0.75em;
    margin-bottom: 4px;
    animation: expand-row .25s linear;
  }
}

@media only screen and (max-width: 500px) {
  .leaderboard-row {
    .place-cell {
      width: 50px;
    }

    .score-cell {
      width: 30px;
    }
  
    .expand-cell {
      width: 20px;
    }
  }
}

@keyframes expand-row {
  0% {
    height: 0px;
  }

  100% {
    height: calc(100%);
  }
}
